const Arrow = ({ ...props }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8898 11.1097C13.2267 11.4465 13.7728 11.4465 14.1096 11.1097C14.4464 10.7729 14.4464 10.2268 14.1096 9.88992L9.60959 5.38993C9.27276 5.0531 8.72666 5.0531 8.38983 5.38993L3.88983 9.88993C3.553 10.2268 3.553 10.7729 3.88983 11.1097C4.22666 11.4465 4.77276 11.4465 5.10959 11.1097L8.99971 7.21956L12.8898 11.1097Z"
      fill="currentColor"
    />
  </svg>
)

export default Arrow
